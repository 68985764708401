import React from 'react';
import { Modal, Select, TextInput, Button } from '@mantine/core';

function SettingsModal({ opened, onClose, newStream, setNewStream, addStream }) {
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title="Add Stream"
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          addStream();
        }}
      >
        <Select
          label="Platform"
          placeholder="Select platform"
          value={newStream.platform}
          onChange={(value) =>
            setNewStream({ ...newStream, platform: value })
          }
          data={[
            { value: "twitch", label: "Twitch" },
            { value: "youtube", label: "YouTube" },
            { value: "vimeo", label: "Vimeo" },
            { value: "iframe", label: "Generic iframe" },
            { value: "clock", label: "Clock" },
          ]}
          style={{ marginBottom: "1rem" }}
        />
        {newStream.platform !== "clock" && (
          <TextInput
            label={
              newStream.platform === "iframe" ? "URL" : "Channel/Video ID"
            }
            placeholder={
              newStream.platform === "iframe"
                ? "Enter URL"
                : `Enter ${newStream.platform} channel/video ID`
            }
            value={newStream.id}
            onChange={(e) =>
              setNewStream({ ...newStream, id: e.target.value })
            }
            style={{ marginBottom: "1rem" }}
          />
        )}
        <Button type="submit" fullWidth>
          Add
        </Button>
      </form>
    </Modal>
  );
}

export default SettingsModal;
