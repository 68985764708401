import { useState, useEffect } from 'react';
import { Button } from '@mantine/core';
import { IconX } from '@tabler/icons-react';

function Clock({ onRemove }) {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => setTime(new Date()), 1000);
    return () => clearInterval(timer);
  }, []);

  const formatTime = (date) => {
    return date.toLocaleTimeString('en-US', {
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    }).replace(/:/g, ':');
  };

  return (
    <div style={{ 
      position: 'relative', 
      width: '100%', 
      height: '100%', 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      backgroundColor: '#000',
      color: '#fff',
      fontSize: '12rem', // Increased font size for better visibility
      fontFamily: 'Arial, sans-serif'
    }}>
      <div style={{ flex: 1, textAlign: 'center' }}>
        {formatTime(time)}
      </div>
      <Button
        onClick={onRemove}
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
          borderRadius: '50%',
          padding: '0',
          width: '30px',
          height: '30px',
          minWidth: 'unset',
        }}
      >
        <IconX size={16} color="black" />
      </Button>
    </div>
  );
}

export default Clock;
