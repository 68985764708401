import { useState, useEffect } from 'react';
import { MantineProvider, AppShell, Button } from '@mantine/core';
import { IconSettings } from '@tabler/icons-react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import VideoEmbed from './components/VideoEmbed';
import Clock from './components/Clock';
import DefaultView from './components/DefaultView.js';
import SettingsModal from './components/SettingsModal';
import { updateQueryParams, parseQueryParams } from './utils/queryParams';
import { getGridLayout } from './utils/gridLayout';

function App() {
  const [streams, setStreams] = useState([]);
  const [newStream, setNewStream] = useState({ platform: 'twitch', id: '' });
  const [modalOpened, setModalOpened] = useState(false);

  useEffect(() => {
    const initialStreams = parseQueryParams();
    setStreams(initialStreams);
  }, []);

  useEffect(() => {
    updateQueryParams(streams);
  }, [streams]);

  const addStream = () => {
    if (newStream.platform === 'clock' || (newStream.id && !streams.some(s => s.platform === newStream.platform && s.id === newStream.id))) {
      setStreams([...streams, newStream]);
      setNewStream({ platform: 'twitch', id: '' });
      setModalOpened(false);
    }
  };

  const removeStream = (streamToRemove) => {
    setStreams(streams.filter(s => !(s.platform === streamToRemove.platform && s.id === streamToRemove.id)));
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const newStreams = Array.from(streams);
    const [reorderedStream] = newStreams.splice(result.source.index, 1);
    newStreams.splice(result.destination.index, 0, reorderedStream);
    setStreams(newStreams);
  };

  return (
    <MantineProvider withGlobalStyles withNormalizeCSS>
      <AppShell>
        <div
          style={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            zIndex: 1000,
          }}
        >
          <Button
            onClick={() => setModalOpened(true)}
            variant="filled"
            style={{
              borderRadius: "50%",
              width: "60px",
              height: "60px",
              padding: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <IconSettings size={24} color="white" />
          </Button>
        </div>
        <SettingsModal
          opened={modalOpened}
          onClose={() => setModalOpened(false)}
          newStream={newStream}
          setNewStream={setNewStream}
          addStream={addStream}
        />
        <div
          style={{
            height: "100vh",
            width: "100vw",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#222",
            position: 'relative',
          }}
        >
          {streams.length === 0 ? (
            <DefaultView 
              style={{ 
                position: 'absolute', 
                top: 0, 
                left: 0, 
                right: 0, 
                bottom: 0, 
                zIndex: 2 
              }} 
              onAddStream={() => setModalOpened(true)}
            />
          ) : (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="streams" direction="horizontal">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{
                      flex: 1,
                      display: 'grid',
                      gridTemplateColumns: getGridLayout(streams.length).columns,
                      gridTemplateRows: getGridLayout(streams.length).rows,
                      gap: '10px',
                      padding: '10px',
                      backgroundColor: '#222',
                      zIndex: 1,
                      position: 'relative',
                    }}
                  >
                    {streams.map((stream, index) => (
                      <Draggable key={`${stream.platform}-${stream.id}`} draggableId={`${stream.platform}-${stream.id}`} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={{
                              ...provided.draggableProps.style,
                              height: '100%',
                              minHeight: '200px',
                            }}
                          >
                            {stream.platform === 'clock' ? (
                              <Clock onRemove={() => removeStream(stream)} dragHandleProps={provided.dragHandleProps} />
                            ) : (
                              <VideoEmbed
                                stream={stream}
                                onRemove={() => removeStream(stream)}
                                style={{
                                  height: '100%',
                                  width: '100%',
                                }}
                                dragHandleProps={provided.dragHandleProps}
                              />
                            )}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </div>
      </AppShell>
    </MantineProvider>
  );
}

export default App;
