export function getGridLayout(streamCount) {
  if (streamCount <= 1) {
    return { columns: '1fr', rows: '1fr' };
  } else if (streamCount <= 2) {
    return { columns: '1fr 1fr', rows: '1fr' };
  } else if (streamCount <= 4) {
    return { columns: '1fr 1fr', rows: '1fr 1fr' };
  } else if (streamCount <= 6) {
    return { columns: '1fr 1fr 1fr', rows: '1fr 1fr' };
  } else if (streamCount <= 9) {
    return { columns: '1fr 1fr 1fr', rows: '1fr 1fr 1fr' };
  } else {
    // For more than 9 streams, you might want to implement a more sophisticated layout
    const cols = Math.ceil(Math.sqrt(streamCount));
    return { 
      columns: `repeat(${cols}, 1fr)`, 
      rows: `repeat(${Math.ceil(streamCount / cols)}, 1fr)` 
    };
  }
}
