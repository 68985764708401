import { IconX, IconGripVertical } from '@tabler/icons-react'; // {{ edit_1 }} Import IconX

function VideoEmbed({ stream, onRemove, style, dragHandleProps }) {
  const getEmbedUrl = () => {
    switch (stream.platform) {
      case 'twitch':
        return `https://player.twitch.tv/?channel=${stream.id}&parent=${window.location.hostname}`;
      case 'youtube':
        return `https://www.youtube.com/embed/${stream.id}`;
      case 'vimeo':
        return `https://player.vimeo.com/video/${stream.id}`;
      case 'iframe':
        return stream.id;
      default:
        return '';
    }
  };

  return (
    <div style={{ ...style, position: 'relative' }}>
      <iframe
        title={stream.id}
        src={getEmbedUrl()}
        height="100%"
        width="100%"
        allowFullScreen
        style={{ border: 'none' }}
      />
      <div style={{ 
        position: 'absolute', 
        top: 5, 
        right: 5, 
        display: 'flex', 
        gap: '5px',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        borderRadius: '4px',
        padding: '2px'
      }}> {/* {{ edit_2 }} Updated container style */}
        <IconGripVertical
          size={24}
          style={{ cursor: 'grab', color: 'white', opacity: 0.7 }}
          {...dragHandleProps}
        />
        <IconX
          size={24}
          style={{ cursor: 'pointer', color: 'white', opacity: 0.7 }}
          onClick={onRemove}
        /> {/* {{ edit_3 }} Replace Button with IconX */}
      </div>
    </div>
  );
}

export default VideoEmbed;
