import React from 'react';
import { Button, Paper } from '@mantine/core';
import { IconPlus, IconBrandTwitch, IconBrandYoutube, IconBrandVimeo, IconClock, IconLink } from '@tabler/icons-react';

function DefaultView({ style, onAddStream }) {
  return (
    <div style={{
      ...style,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#222',
    }}>
      <img src="/atomic-logo.png" alt="Atomic Logo" style={{ width: '150px', marginBottom: '20px' }} />
      <h1 style={{ color: 'white', textAlign: 'center', margin: '30px 0' }}>Atomic Multiviewer</h1>
      
      {/* Giant plus sign */}
      <Button 
        onClick={onAddStream} 
        size="xl" 
        style={{ 
          fontSize: '5rem', 
          width: '150px', 
          height: '150px', 
          borderRadius: '50%', 
          margin: '30px 0',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#444',
          color: 'white',
          border: 'none',
          cursor: 'pointer',
        }}
      >
        <IconPlus size={80} />
      </Button>

      <Paper padding="md" style={{ backgroundColor: '#333', maxWidth: '600px', width: '90%' }}>
        <h2 style={{ color: 'white', textAlign: 'center' }}>Supported Sources</h2>
        <p style={{ color: 'white', textAlign: 'center', margin: '0 40px' }}>
          Get started by adding your favorite streams using the button above.
        </p>
        <div style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gap: '10px',
          margin: '20px 10px',
        }}>
          {[
            { name: 'Twitch', icon: <IconBrandTwitch size={40} /> },
            { name: 'YouTube', icon: <IconBrandYoutube size={40} /> },
            { name: 'Vimeo', icon: <IconBrandVimeo size={40} /> },
            { name: 'Iframe', icon: <IconLink size={40} /> },
            { name: 'Clock', icon: <IconClock size={40} /> },
          ].map(({ name, icon }) => (
            <div key={name} style={{ textAlign: 'center', color: 'white' }}>
              {icon}
              <p>{name}</p>
            </div>
          ))}
        </div>
      </Paper>
    </div>
  );
}

export default DefaultView;
