export function updateQueryParams(streams) {
  const params = new URLSearchParams();
  streams.forEach((stream, index) => {
    params.append(`s${index}`, `${stream.platform}:${stream.id}`);
  });
  window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
}

export function parseQueryParams() {
  const params = new URLSearchParams(window.location.search);
  const streams = [];
  for (const [key, value] of params.entries()) {
    if (key.startsWith('s')) {
      const [platform, id] = value.split(':');
      streams.push({ platform, id: platform === 'clock' ? '' : id });
    }
  }
  return streams;
}
